<template>
    <div class="markFour">
        <img src="http://iv.okvu.cn/vugw/img/bg2.jpg" alt="" class="mgBg" />
        <div class="mfBox">
            <!-- 标题 -->
            <div class="mfTitle">两种不同全网型官网</div>
            <!-- 左边区域 -->
            <div class="mfLeftBox">
                <!-- 头部文字 -->
                <div class="mflTopText">
                    <div class="mflImg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="miImg" />
                    </div>
                    <div class="mflBig">PC官网、手机官网+小程序</div>
                    <div class="mflSmall">
                        管理方式：一个后台管理，发布不同内容到各个站点。适用客户：业务偏重于移动端，需要不同终端呈现不同内容。
                    </div>
                </div>
                <!-- 图片 -->
                <div class="mflImg">
                    <img
                        class="mflimg wow animate__animated animate__fadeInUp"
                        src="https://iv.okvu.cn/vugw/img/img3.png"
                        alt=""
                    />
                </div>
            </div>
            <!-- 右边区域 -->
            <div class="mfRightBox">
                <!-- 图片 -->
                <div class="mfrImg">
                    <img
                        class="mfrimg wow animate__animated animate__fadeInDown"
                        src="https://iv.okvu.cn/vugw/img/img4.png"
                        alt=""
                    />
                </div>
                <!-- 底部文字 -->
                <div class="mfrBottomText">
                    <div class="mflImg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="miImg" />
                    </div>
                    <div class="mfrBig">响应式自适应官网</div>
                    <div class="mfrSmall">
                        管理方式：一个后台并且发布相同内容到所有站点。适用客户：产品、企业形象、品牌，需要保持整体传播和认知。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MarkFour",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.markFour {
    width: 100%;
    height: 800px;
    position: relative;
    text-align: left;
    .mgBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .mfBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .mfTitle {
            width: 30%;
            height: 50px;
            margin: 0 auto;
            margin-top: 80px;
            line-height: 50px;
            text-align: center;
            font-weight: 900;
            font-size: 30px;
            color: white;
        }
        // 左边区域
        .mfLeftBox {
            width: 40%;
            height: 600px;
            margin-top: 50px;
            float: left;
            // 头部文字
            .mflTopText {
                width: 100%;
                height: 40%;
                margin-left: 200px;
                color: white;
                position: relative;
                .mflImg {
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    top: 0;
                    left: -100px;
                    overflow: hidden;
                    .miImg {
                        position: absolute;
                        top: -320px;
                        right: -520px;
                    }
                }
                .mflBig {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 30px;
                    font-weight: 900;
                }
                .mflSmall {
                    width: 75%;
                    height: 150px;
                    line-height: 30px;
                    margin-top: 20px;
                    font-size: 20px;
                }
            }
            // 图片
            .mflImg {
                width: 100%;
                height: 60%;
                text-align: center;
                .mflimg {
                }
            }
        }
        // 右区域
        .mfRightBox {
            width: 40%;
            height: 600px;
            margin-top: 50px;
            float: right;
            // 图片
            .mfrImg {
                width: 100%;
                height: 60%;
                text-align: center;
                .mfrimg {
                }
            }
            // 底部文字
            .mfrBottomText {
                width: 100%;
                height: 40%;
                margin-right: 200px;
                position: relative;
                .mflImg {
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    top: 0;
                    left: -100px;
                    overflow: hidden;
                    .miImg {
                        position: absolute;
                        top: -320px;
                        right: -425px;
                    }
                }
                .mfrBig {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 30px;
                    font-weight: 900;
                }
                .mfrSmall {
                    width: 75%;
                    height: 150px;
                    line-height: 30px;
                    margin-top: 20px;
                    font-size: 20px;
                }
            }
        }
    }
}
</style>
