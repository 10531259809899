<template>
    <div class="marketing">
        <!-- 内容一 -->
        <MarkOne></MarkOne>
        <!-- 内容二 -->
        <MarkTwo></MarkTwo>
        <!-- 内容三 -->
        <MarkThree></MarkThree>
        <!-- 内容四 -->
        <MarkFour></MarkFour>
        <!-- 内容五 -->
        <MarkFive></MarkFive>
    </div>
</template>

<script>
import MarkOne from "./MarkContent/MarkOne.vue";
import MarkTwo from "./MarkContent/MarkTwo.vue";
import MarkThree from "./MarkContent/MarkThree.vue";
import MarkFour from "./MarkContent/MarkFour.vue";
import MarkFive from "./MarkContent/MarkFive.vue";

export default {
    name: "Marketing",
    components: {
        MarkOne,
        MarkTwo,
        MarkThree,
        MarkFour,
        MarkFive,
    },
};
</script>

<style lang="less" scoped>
.marketing {
    min-width: 1423px;
    margin: 0 auto;
    // min-width: 1423px;
}
</style>
