<template>
    <div class="markOne" v-if="getAdvData(40).children" :style="`background-image: url(${getAdvData(40).children[0].image});`">
        <a :href="getAdvData(40).children[0].url" target="_blank" rel="noopener noreferrer">
            <div class="moBox">
                <!-- 右图 -->
                <div class="moLeftImg">
                    <img class="tu" :src="getAdvData(40).children[0].children.find(e => e.id == 321).image" />
                </div>
                <!-- 左上图 -->
                <div class="moTopImg">
                    <img class="tu" :src="getAdvData(40).children[0].children.find(e => e.id == 322).image" />
                </div>
                <!-- 左下图 -->
                <div class="moBottomImg">
                    <img class="tu" :src="getAdvData(40).children[0].children.find(e => e.id == 351).image" />
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "MarkOne",
    inject: ["getAdvData"],
};
</script>

<style lang="less" scoped>
.markOne {
    width: 100%;
    height: 700px;
    position: relative;
    background-repeat: no-repeat;
    // background-size: auto 100%;
    background-size: 100% auto;
    background-position: center;
    //   center;
    .moBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
    }
    // 右图
    .moLeftImg {
        width: 40%;
        height: 300px;
        float: left;
        margin-top: 200px;
    }
    // 左上图
    .moTopImg {
        width: 40%;
        height: 32%;
        margin-top: 100px;
        float: right;
    }
    // 左下图
    .moBottomImg {
        width: 40%;
        height: 40%;
        float: right;
        margin-top: 10px;
        margin-right: 50px;
    }
    .tu {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
