<template>
    <div class="markFive">
        <img src="http://iv.okvu.cn/vugw/img/bg3.jpg" alt="" class="mfBg" />
        <div class="mFBox">
            <!--标题 -->
            <div class="mFTitle">威有八大优势</div>
            <!-- 列表 -->
            <div class="mFLists">
                <ul>
                    <li class="mFLi wow animate__animated animate__slideInUp">
                        <div class="mFNum">01</div>
                        <div class="nFText">团队经验丰富，老员工多</div>
                    </li>
                    <li class="mFLi wow animate__animated animate__slideInUp" data-wow-delay="0.1s">
                        <div class="mFNum">02</div>
                        <div class="nFText">原创定制，艺术与技术完美融合</div>
                    </li>
                    <li class="mFLi wow animate__animated animate__slideInUp" data-wow-delay="0.2s">
                        <div class="mFNum">03</div>
                        <div class="nFText">专职咨询、专业项目管理，实现网站成功率99%</div>
                    </li>
                    <li class="mFLi wow animate__animated animate__slideInUp" data-wow-delay="0.3s">
                        <div class="mFNum">04</div>
                        <div class="nFText">科学完备的网站改版及内容迁移方法论</div>
                    </li>
                    <li class="mFLi wow animate__animated animate__slideInUp" data-wow-delay="0.4s">
                        <div class="mFNum">05</div>
                        <div class="nFText">超级战车让客户“真正”获得原创定制的网站</div>
                    </li>
                    <li class="mFLi wow animate__animated animate__slideInUp" data-wow-delay="0.5s">
                        <div class="mFNum">06</div>
                        <div class="nFText">ezSITE、ezEIP网站系统。让客户网站随需而变</div>
                    </li>
                    <li class="mFLi wow animate__animated animate__slideInUp" data-wow-delay="0.6s">
                        <div class="mFNum">07</div>
                        <div class="nFText">关注网站优化，实现网络营销</div>
                    </li>
                    <li class="mFLi wow animate__animated animate__slideInUp" data-wow-delay="0.7s">
                        <div class="mFNum">08</div>
                        <div class="nFText">网站维护服务周到，网站运营后顾无忧</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MarkFive",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.markFive {
    width: 100%;
    height: 700px;
    position: relative;
    .mfBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .mFBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .mFTitle {
            width: 30%;
            height: 50px;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
            font-weight: 900;
            text-align: center;
            font-size: 36px;
            color: #ff6c00;
            padding-top: 50px;
        }
        // 列表
        .mFLists {
            width: 100%;
            height: 550px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                .mFLi {
                    width: 21%;
                    height: 200px;
                    margin-top: 50px;
                    position: relative;
                    color: white;
                    background: url("https://iv.okvu.cn/vugw/img/vu1111.png") no-repeat center;
                    background-position: -793px -837px;
                    // transition: 0.5s;
                    .mFNum {
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        font-weight: 900;
                        font-size: 30px;
                        position: absolute;
                        top: 10px;
                        left: 40%;
                    }
                    .nFText {
                        width: 70%;
                        height: 80%;
                        font-size: 20px;
                        font-weight: 900;
                        position: absolute;
                        top: 80px;
                        left: 40px;
                    }
                }
                .mFLi:hover {
                    // background: url('https://iv.okvu.cn/vugw/img/vu1111.png') no-repeat center;
                    background-position: -790px -1046px;
                }
            }
        }
    }
}
</style>
